* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Login */

.login-left-section {
  height: 100vh;
  width: 100%;
  background-image: url(./Images/login-left-section.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
}

.login-left-section h2 {
  color: white;
  font-size: 48px;
  font-weight: bold;
  font-family: system-ui;

}

.login-left-section p {
  color: white;
  font-family: system-ui;
}

.ask-to-sign h2 {
  font-weight: bold;
  justify-content: center;
}

.ask-to-sign h2 img {
  margin-top: -20px;
}

span.toggle-password-icon {
  position: relative;
  top: -35px;
  right: 20px !important;
  float: right;
  font-size: 21px;
  color: #9A9AB0;
}

.form-input {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 100px !important;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: 12px !important;
}

.form-input:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #F10B80 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #f10b8248 !important;
}

.form-label {
  color: #707070 !important;
  font-weight: 500;
}

.forgot-password {
  color: #A10C92;
  font-weight: 600;
  margin-top: -45px;
  /* font-family: sans-serif; */
  text-decoration: none;
}

.remeber-me {
  color: #707070;
}

.sign-in-btn {
  background-image: linear-gradient(to right, #F10B80, #830D9A);
  color: white;
  padding: 10px;
  width: 100%;
  border-radius: 100px;
}

.continue-with {
  text-align: center;
  position: relative;
  color: #707070;
}

.continue-with::after {
  content: '';
  width: 140px;
  height: 1px;
  position: absolute;
  background-color: #b1b1b1;
  top: 13px;
  margin-left: 10px;
}

.continue-with::before {
  content: '';
  width: 140px;
  height: 1px;
  position: absolute;
  background-color: #b1b1b1;
  top: 13px;
  margin-left: -150px;
}

.google-btn {
  padding: 10px 40px;
  border: 1px solid #989898;
  border-radius: 10px;
  color: #707070;
  font-weight: 600;
  display: flex;
}

.button-center {
  justify-content: center;
}

.login-right-section {
  height: 100vh;
  overflow-y: scroll;

}

/* .login-right-section-p{
  position: absolute;
  top: 20px;
} */
.login-right-section-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

#input-outline:focus-visible {
  box-shadow: 0px 4px 6px #d1007e !important;
  border: none !important;
  outline-color: #d1007e !important;
}

.otp-span {
  text-align: center;
  color: #707070;
  margin: 0;
  padding: 2px;
}

.upload-file {
  border: 2px dashed #707070 !important;
  padding: 40px !important;
  border-radius: 100px !important;
  background-color: transparent !important;
}

.upload-file-icon {
  font-size: 25px;
  color: #707070;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #F10B80 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #f10b8248 !important;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  height: 50px;
  /* border-radius: 5400px !important; */
}

.custom-header-compare {
  color: #707070 !important;
  background-color: white !important;
  font-weight: 700 !important;
  box-shadow: 1px 1px 20px rgb(219, 219, 219) !important;
}

.nav-typography {
  border-radius: 0px !important;
  font-weight: 500 !important;
  color: #183B56 !important;
  font-family: system-ui !important;
  margin: 0px 20px -28px 0px !important;
  padding-bottom: 22px !important;
  text-transform: capitalize !important;

}

/* .logo-nav{

} */
.link_decorations_white {
  text-decoration: none;
  color: rgb(211, 211, 211);
}

.about_us_ul li {
  color: #5A7184;
  /* font-family: "Rufina"; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  list-style: none;
  margin-left: -30px;
}

.enter_email {
  background-color: white;
  color: black;
  padding: 10px 70px;
  border-radius: 100px;
}

.subscribe_btn {
  background-color: #29ACE3;
  color: white;
  padding: 10px 70px;
  border-radius: 100px;
}

.footer_logo img {
  width: 245px !important;
  max-width: 245px !important;
}

.copy_right {
  color: #5A7184;
  font-size: 15px;
  text-align: center;
}

p {
  margin-top: 0;
  margin-bottom: 0px !important;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0px !important;
}

.footer-main {
  background-color: #FBF5FA;
}

.footer_logo img {
  width: 100px !important;
}

.footer-lorem {
  color: #5A7184;
  font-size: 15px;
  font-family: system-ui;
  line-height: 28px;
  font-weight: 400;
}

.about_us_ul li a {
  color: #5A7184;
  /* font-family: "Rufina"; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  list-style: none;
  /* margin-left: -30px; */
}

.search-note {
  height: auto;
  background-color: white;
  /* min-width: 300px !important; */
  width: 700px;
  border-radius: 20px;
  box-shadow: 1px 1px 20px rgb(219, 219, 219) !important;
}

@media screen and (min-width:70px) and (max-width: 1000px) {
  .search-note {
    width: auto;
  }
}

.background-main {
  background-color: #F8F8F8;
}

.search-note-h2 {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}

.robert-p {
  font-weight: 500;
  color: #535353;
}

.upload-file-photo {
  border: 2px dashed #C8C8C8 !important;
  padding: 30px 15px !important;
  border-radius: 10px !important;
  background-color: transparent !important;
  flex-direction: column;
  color: #C8C8C8 !important;
  font-size: 15px;
}

.upload-file-icon-photo {
  font-size: 25px;
  color: #C8C8C8;
}

.save-draft-btn {
  color: white;
  background-color: black;
  padding: 10px 0px;
  border-radius: 100px;
  width: 200px;
}

.search-btn {
  background-image: linear-gradient(to right, #F10B80, #830D9A);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  float: right;
  width: 200px;
  text-align: center;
}

@media screen and (min-width:70px) and (max-width: 700px) {
  .search-btn {
    float: none;
    width: 100%;
    margin: auto;
  }

  .save-draft-btn {
    margin: auto;
    width: 100%;
  }
}

.save-draft-btns {
  /* justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center; */
}

.search-bar {
  /* padding: 10px; */
  border: 1px solid #9A9AB0;
  border-radius: 100px;
  color: #9CA4AB;
  width: 250px !important;
  overflow: hidden;
  background-color: white;
}

.search-bar input {
  outline: none;
  background-color: transparent;
  width: 100%;

}

.search-bar-right {
  position: absolute;
  right: 0;
}

.card_heading {
  font-size: 20px !important;
  /* font-weight: 700 !important;  */
  color: #434243;
}

.view_profile_b {
  color: #29ACE3 !important;
}

.enquire_now_b {
  color: #DA2700 !important;
  float: right;
}

.home-card-sw {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 13px !important;
  box-shadow: 1px 1px 5px #00000021 !important;
  overflow: hidden;
  max-width: 345px;
}

.open-now-btnn {
  position: absolute;
  padding: 5px 5px;
  border-radius: 100px;
  margin: 0px;
  right: 0;
  color: green;
}

.card-pera {
  font-size: 17px !important;
}

.breadcrumbs a {
  color: #6F6F6F;
  text-decoration: none;
  position: relative;
  margin-top: -20px !important;
}

.user-profile {
  border-radius: 100px;
  width: 120px;
}

.user-name {
  font-weight: 700;
  font-size: 22px;
}

.search-btn-invitation {
  background-image: linear-gradient(to right, #F10B80, #830D9A);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  float: right;
  width: 200px;
  text-align: center;
}

.user-description {
  color: #7F7F7F;
  line-height: 29px;
  font-weight: 600;
}

.user-description-rating {
  color: #7F7F7F;
}

.link-decoration {
  text-decoration: none;
}

.breadcrumbs {
  position: relative;
  top: -33px;
}

.bar-icon {
  background-color: #EDEDED;
  border-radius: 100px;
  padding: 5px 10px;
  float: right;
  color: #656565;
  /* margin: 10px; */
}

.chat-profile {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

.chat-media {
  width: 100px !important;
  height: auto;
  border-radius: 10px;
}

.chat-content {
  background-color: #E6F3FF;
  max-width: 80%;
  border-radius: 0px 15px 0px 15px;
}

.user-chat-content {
  background-color: #FDDEEE;
  max-width: 80%;
  border-radius: 0px 15px 0px 15px;
}

.user-side-chat-fixed {
  justify-content: right;
}

.are-you-sure-heading {
  text-align: center;
  color: white;
  background-image: linear-gradient(to right, #F10B80, #830D9A);
}

.modal-description-p {
  color: #898989;
  text-align: center;
}

.modal-button {
  position: relative;
  top: 26px;
}

.border-rduis {
  border-radius: 10px;
  overflow: hidden;
}

.search-bar-image {
  width: 30px;
}

.inner-shadow {
  box-shadow: 1px 1px 15px rgb(217 217 217);
  height: auto;
  border-radius: 10px;
}

/* .tabs-tab:focus{
  background-color: red !important;
}
.tabs-tab:active{
  background-color: red !important;
} */

.Mui-selected {
  background-image: linear-gradient(to right, #F10B80, #830D9A);
  /* color: white !important; */
  padding: 10px !important;
  min-height: auto !important;
}

/* VerticalNavTabs.css */
#vertical-nav .nav-link {
  background-color: #f8f9fa;
  /* Background color for unselected tabs */
  border: 1px solid transparent;
  /* Border for unselected tabs */
  border-radius: 0;
  /* Optional: Remove border radius */
}

#vertical-nav .nav-link.active {
  background-color: #007bff;
  /* Background color for selected tab */
  border-color: #007bff;
  /* Border color for selected tab */
  color: #fff;
  /* Text color for selected tab */
}

/* Optional: Hover effect */
#vertical-nav .nav-link:hover {
  background-color: #e9ecef;
  /* Background color on hover */
}

.tabs-tab {
  border-radius: 100px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  -webkit-box-pack: start !important;
  display: -webkit-inline-box !important;
}

.css-10d9dml-MuiTabs-indicator {
  display: none;
}
.MuiTabs-indicator{
  display: none;
}

.side-bar-profile {
  width: 80px;
  height: 80px;
  border-radius: 100px;
}

.side-bar-name h2 {
  font-size: 20px;
  color: #4B4B4B;
  font-weight: 700;
  text-align: center;
}

.side-bar-name p {
  color: #898989;
  text-align: center;
}

.side-bar-name h3 {
  text-align: center;
  background: linear-gradient(to right, #F10B80, #830D9A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}

.custom-tab-panel {
  padding: 0px !important;
}

.MuiBox-root {
  /* Add your custom styles here */
  padding: 0px !important;
}

.overview-heading {
  color: #2F2F2F;
  font-size: 18px;
  font-weight: 600;
}

.side-bar-name-nav h2 {
  font-size: 20px;
  color: #4B4B4B;
  font-weight: 700;
}

.side-bar-name-nav p {
  color: #898989;
}

.side-nav-info {
  color: #303030;
}

.change-phone-number-btn {
  color: #820D9B;
  padding: 7px 20px;
  border: 2px solid #820D9B;
  border-radius: 10px;
}

.ask-to-sign-scroll {
  overflow-y: scroll;
  height: 400px;
}

.payment-btn {
  background-image: linear-gradient(to right, #F10B80, #830D9A);
  color: white;
  padding: 10px 70px;
  /* width: 100%; */
  border-radius: 100px;
}

.pay-now-btn {
  color: white;
  padding: 10px 70px;
  border-radius: 100px;
  background-color: black;
}

.overview-heading-wallet {
  color: #373737;
  font-size: 20px;
  font-weight: 600;
}

.draft-driver {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
}

.overview-heading-h5 {
  color: #C70B89;
  font-size: 15px;
}

.contracts-nav-heading {
  background-image: none !important;
  color: black !important;
  text-transform: capitalize !important;
  font-size: 17px !important;
}

.MuiTabs-indicator {
  background-color: #B50B8D !important;
}

.MuiTabs-flexContainer {
  justify-content: center !important;
}

.contracts-nav-image {
  width: 80px !important;
  border-radius: 100px;
}

.contracts-nav-card {
  text-align: left;
}

.contracts-nav-card h4 {
  font-size: 18px;
}

.view-contract {
  background: linear-gradient(to right, #F10B80, #830D9A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  float: right;
  font-size: 16px;
  font-weight: 600;
}

.activecontract h4 {
  font-size: 18px;
}

.activecontract p {
  color: #7D7D7D;
}

/* .css-b8tju9{
  z-index: 999 !important;
} */

.form-label-dark {
  color: #000000 !important;
  font-weight: 500;
}

.x-mark {
  float: right;
  width: 22px;
  cursor: pointer;
}

.custom-modal-style {
  width: 100px !important;
}

.form-input-modal {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: 12px !important;
  border-radius: 10px !important;
}

.form-input-modal:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #F10B80 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #f10b8248 !important;
}

.my-dispute-card {
  border: 1px solid #DDDDDD;
}

.in-review {
  padding: 5px 10px;
  background-color: #D9F1FF;
  color: #02A0E3;
  border-radius: 100px;
}

.transection-info h2 {
  font-size: 16px !important;
  color: black !important;
}

.transection-info-icon {
  width: 45px;
}

.transection-info-doller {
  color: #00C013;
  font-weight: bold;
  position: relative;
  top: -35px;
}

.transection-info-doller-withdrawal {
  color: #FF1D1D;
  font-weight: bold;
  position: relative;
  top: -35px;
}

.withdraw-btn {
  background-image: linear-gradient(to right, #F10B80, #830D9A);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  width: 200px;
  text-align: center;
  float: right;
  position: relative;
  top: -50px;
}

.withdraw-btn-ok {
  background-image: linear-gradient(to right, #F10B80, #830D9A);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  width: 200px;
  text-align: center;
  /* float: right; */
  position: relative;
  /* top: -50px; */
}

.withdraw-btn-ok {
  background-image: linear-gradient(to right, #F10B80, #830D9A);
  color: white;
  padding: 10px 0px;
  border-radius: 100px;
  width: 200px;
  text-align: center;
  /* float: right; */
  position: relative;
  /* top: -50px; */
}

.cancel-btn {
  background: linear-gradient(to right, #F10B80, #830D9A);
  /* color: white; */
  padding: 10px 30px;
  border-radius: 100px;
  width: auto;
  text-align: center;
  /* float: right; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 2px solid #F10B80;
}

.logout-btn {
  background-image: linear-gradient(to right, #F10B80, #830D9A);
  color: white;
  padding: 10px 30px;
  border-radius: 100px;
  text-align: center;
  position: relative;
}

.landing-section {
  background-color: #FBF5FA;
  height: auto;
}

.build-business h2 {
  color: #183B56;
  font-size: 45px;
  font-weight: bold;
}

.build-business p {
  color: #5A7184;
}

.animation-line {
  position: absolute;
  right: 20px;
  top: 100px;
  animation-name: animationline;
  animation-duration: 10s;
  animation-delay: -2s;
  animation-iteration-count: infinite;

}

@keyframes animationline {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-right: 300px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.animation-rating {
  position: absolute;
  margin-left: 0px;
  top: 150px;
  animation-name: animationrating;
  animation-duration: 10s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
  z-index: 1;

}

@keyframes animationrating {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: 200px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.animation-done {
  position: absolute;
  right: 350px;
  top: 200px;
  animation-name: animationdone;
  animation-duration: 10s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
  z-index: 1;

}

@keyframes animationdone {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: 100px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.animation-visitor {
  position: absolute;
  right: 300px;
  top: 400px;
  animation-name: animationvisitor;
  animation-duration: 10s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
  z-index: 11;

}

@keyframes animationvisitor {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: 50px;
    margin-right: 100px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.animation-icon {
  position: absolute;
  right: 950px;
  top: 500px;
  animation-name: animationicon;
  animation-duration: 10s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
  z-index: 1;

}

@keyframes animationicon {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    transform: rotate(360deg);
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.animation {
  position: absolute;
  left: 0;
  top: 400px;
  /* animation-name: animationicon; */
  animation-duration: 10s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
  z-index: 1;

}

.animtion-girl {
  position: relative;
  z-index: 10;
  width: 500px;
  margin: auto;
}

@media screen and (min-width:0px) and (max-width: 1000px) {
  #animation-none {
    display: none;
  }

  .customer-saying {
    background-image: none !important;
  }

  .customer-saying-card {
    margin-top: 0px !important;
  }

  .subscribe-search-section {
    width: auto !important;
  }

}

@media screen and (min-width:1000px) and (max-width: 1500px) {
  .animation-done {
    position: absolute;
    right: 0px;
    top: 400px;
    animation-name: animationdone;
    animation-duration: 10s;
    animation-delay: -2s;
    animation-iteration-count: infinite;
    z-index: 1;

  }

  .animation-visitor {
    position: absolute;
    right: 200px;
    top: 400px;
    animation-name: animationvisitor;
    animation-duration: 10s;
    animation-delay: -2s;
    animation-iteration-count: infinite;
    z-index: 11;

  }

  .animation-icon {
    position: absolute;
    right: 600px;
    top: 400px;
    animation-name: animationicon;
    animation-duration: 10s;
    animation-delay: -2s;
    animation-iteration-count: infinite;
    z-index: 1;

  }
}

.why-choose-h5 {
  text-align: center;
  color: #183B56;
  font-size: 28px;
  font-weight: bold;
}

.why-choose-card {
  box-shadow: 1px 1px 10px rgb(229, 229, 229);
  border-radius: 10px;
}

.why-choose-card h5 {
  color: #183C56;
}

.why-choose-card p {
  color: #5A7184;
  text-align: justify;
}

.trusted-us {
  background-color: #F8FCFD;
}

.trusted-us-image {
  width: 400px;
  margin: auto;
}

.trusted-us p {
  color: #5A7184;
  text-align: justify;
}

.blog-card {
  box-shadow: 1px 1px 10px rgb(229, 229, 229);
  border-radius: 10px;
  background-color: #F8FCFD;
}

.blog-card img {
  border-radius: 10px;
}

.blog-card p {
  color: #766A6A;
}

.blog-card h5 {
  color: #183B56;
}

.blog-card button {
  color: #183B56;
}

.blog-card-icon {
  font-size: 14px;
}

/* .customer-saying {
  height: 700px;
  background-image: url(./Images/saying.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
} */

.customer-saying-p {
  color: #183B56;

  font-weight: 600;
  line-height: 25px;
}

.customer-saying-william {
  color: #820D9B;
  font-weight: 700;
}

.customer-saying-card {
  margin-top: 120px;
}

.subscribe-section {
  background-image: url(./Images/Subscribe.png);
  border-radius: 20px;
}

.subscribe-search-section {
  width: 500px;
  background-color: white;
  height: auto;
  border-radius: 10px;
}

.subscribe-section h2 {
  color: white;
  font-size: 40px;
  max-width: 400px;
  margin: auto;
  font-weight: bold;
}

.subscribe-input {
  box-shadow: none !important;
}

.subscribe-search-section input {
  height: 40px;
  outline: none;
}

.subscribe-search-section button {
  background-image: linear-gradient(to right, #F10B80, #830D9A);
  color: white;
  padding: 17px 40px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  float: right;
}

@media screen and (min-width:0px) and (max-width: 500px) {
  .subscribe-search-section input {
    width: 100px !important;
  }
}

.MuiBarElement-root {
  fill: #BC0C8C !important;
}

.total-earning {
  background-color: #F9F9F9;
  border-radius: 10px;
  width: 200px !important;
  text-align: left;
}

.user-identification-icon {
  width: 17px;
}
.identification-modal{
  cursor: pointer;
}
.video-img{
  width: 120px;
  height: 120px;
  border-radius: 100%;
  margin: auto;
}
.round-bold{
  font-weight: 500;
  color: #4C4C4C;
}
.stop-btn{
  background-color: black;
  color: white;
  padding: 8px 40px;
  border-radius: 100px;
  text-align: center;
  position: relative;
  width: 50%;
}
@media screen and (min-width:70px) and (max-width: 776px) {
  .MuiBox-root{
    width: 100% !important;
  }
}
.image-inline{
  display: inline !important;
}
.Mui-selected {
  background-image: linear-gradient(to right, #F10B80, #830D9A);
  color: white !important;
  border-bottom: 1px solid #a40c92 !important;
  
}
.MuiButtonBase-root{
/* padding: 10px !important; */
  min-height: auto !important;
  /* color: black !important; */
  align-items: start !important;
}
/* .tabs-tab{
  color: white !important;
} */
.contracts-nav-heading{
  color: rgb(60, 60, 60) !important;
}
hr {
  margin: 1rem 0 !important;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
}
ul.about_us_ul {
  margin-left: 31px;
}
.ask-to-sign h2 {
  font-weight: 700;
  justify-content: center;
  font-size: 29px;
}
.delete-draft{
  width: 17px;
  float: right;
  /* position: relative; */
  right: 0;
  margin: 0px 10px;
  cursor: pointer;
}
.fillter-btn{
  border: 2px solid #EEEEEE !important;
  border-radius: 100px !important;
  color: #878787;
  padding: 10px 30px !important;
  display: flex !important;
}
.dropdown-toggle::after{
  display: inline-block;
  margin-left: 1.255em;
  vertical-align: .255em;
  content: "";
  border-top: 0.3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  margin-top: 9px;
  border-left: .3em solid transparent;
  top: 10px;

}

/* animation start  */

.animation-saying-1{
  position: absolute;
  /* right: 20px; */
  top: 0px;
  animation-name: animationsaying1;
  animation-duration: 20s;
  /* animation-delay: -2s; */
  animation-iteration-count: infinite;
  width: 70px;
}


@keyframes animationsaying1 {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: 300px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.animation-saying-2{
  position: absolute;
  /* right: 20px; */
  top: 0px;
  left: 70px;
  animation-name: animationsaying2;
  animation-duration: 20s;
  /* animation-delay: -2s; */
  animation-iteration-count: infinite;
  width: 70px;
}


@keyframes animationsaying2 {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: 400px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.animation-saying-3{
  position: absolute;
  /* right: 20px; */
  top: 400px;
  left: 100px;
  animation-name: animationsaying3;
  animation-duration: 10s;
  /* animation-delay: -2s; */
  animation-iteration-count: infinite;
  width: 70px;
}


@keyframes animationsaying3 {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: -400px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.animation-saying-4{
  position: absolute;
  /* right: 20px; */
  top: 400px;
  left: 150px;
  animation-name: animationsaying4;
  animation-duration: 15s;
  /* animation-delay: -2s; */
  animation-iteration-count: infinite;
  width: 70px;
}


@keyframes animationsaying4 {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: -400px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}
.animation-saying-5{
  position: absolute;
  /* right: 20px; */
  top: 400px;
  left: 200px;
  animation-name: animationsaying5;
  animation-duration: 20s;
  /* animation-delay: -2s; */
  animation-iteration-count: infinite;
  width: 70px;
}


@keyframes animationsaying5 {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: -400px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}
.animation-saying-6{
  position: absolute;
  /* right: 20px; */
  top: 400px;
  left: 250px;
  animation-name: animationsaying6;
  animation-duration: 10s;
  /* animation-delay: -2s; */
  animation-iteration-count: infinite;
  width: 70px;
}


@keyframes animationsaying6 {
  0% {
    margin-right: 0px;
    margin-top: 0px;
  }

  50% {
    margin-top: -400px;
  }

  100% {
    margin-right: 0px;
    margin-top: 0px;
  }
}


@media screen and (min-width:70px) and (max-width: 1000px) {
  .none-section{
    display: none;
  }
}